





























































































































































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "@/components/FormContainer.vue";
import { Guid } from "guid-typescript";
import DatePicker from "@/components/DatePicker.vue";
import ModelTable from "@/components/ModelTable.vue";
import ApplicantForm from "@/components/forms/ApplicantForm.vue";
import { Applicant, Criterion, Score } from "@/data/models/OpenCall";
import ImportDialog from "@/components/ImportDialog.vue";
import Loader from "@/components/Loader.vue";
import openPdf from "@/lib/openPdf";
import { saveAs } from "file-saver";
import InMemoryDataProvider from "@/lib/InMemory/InMemoryDataProvider";
@Component({
  components: {
    FormContainer,
    DatePicker,
    ModelTable,
    ApplicantForm,
    ImportDialog,
    Loader
  }
})
export default class Scores extends Vue {
  @Prop()
  public id!: string;
  public curentApplicant!: string;

  @Prop()
  public crud!: ICrudClient<Score>;

  public provider: DataProvider<any> | null = null;
  private applicantProvider: DataProvider<Applicant> | null = null;
  private applicants: any = [];
  private selectedApplicant: any = null;
  private criteriaProvider: DataProvider<Criterion> | null = null;
  private criteria: any = [];
  private importDialog = false;
  private columns: any = null;
  private rows: {
    [key: string]: any;
  } | null = null;
  private oneChange = true;
  private submitting = false;
  private maxScore = 3;
  private utc = new Date().toJSON().slice(0, 10);
  private openCallName!: string;
  private addColumn(scorerNumber: number) {
    this.columns.push({
      name: "scorer_" + scorerNumber,
      label: "Scorer " + scorerNumber,
      headerClasses: "bg-primary text-white",
      scorerNumber: scorerNumber - 1,
      field: (row: any) => row.scores[scorerNumber - 1].value,
      update: (row: any, val: any) => {
        this.oneChange = true;
        row.scores[scorerNumber - 1].value = val;
      }
    });
  }

  async initialize() {
    this.columns = [
      {
        name: "criterion.name",
        label: "Criterion",
        align: "left",
        sortable: false,
        headerClasses: "bg-primary text-white",
        field: (r: any) => r.criterion.name
      }
    ];

    this.openCallName = (
      await this.$service.providers.openCalls.fetchItemAsync(this.id)
    ).title;

    this.applicantProvider = this.$service.providers.openCallApplicant(this.id);

    this.criteriaProvider = this.$service.providers.openCallCriteria(this.id);
    this.applicants = (
      await this.applicantProvider.fetchItemsAsync({ orderby: "Name" })
    ).items;

    for (let i = 1; i <= this.maxScore; i++) {
      this.addColumn(i);
    }

    this.criteria = (
      await this.criteriaProvider.fetchItemsAsync({
        orderby: "Priority"
      })
    ).items;

    this.orderCriteria();

    if (this.applicants.length > 0) {
      this.selectedApplicant = this.applicants[0];
    }
  }

  orderCriteria() {
    const parentCriteria = this.criteria.filter(
      (c: any) => c.parentCriterionId == null
    );
    const childCriteria = this.criteria.filter(
      (c: any) => c.parentCriterionId != null
    );

    const orderedCriteria = [];
    for (const parent of parentCriteria) {
      orderedCriteria.push(parent);
      for (const child of childCriteria) {
        if (child.parentCriterionId === parent.id) orderedCriteria.push(child);
      }
    }
    this.criteria = orderedCriteria;
  }

  @Watch("selectedApplicant", { immediate: false })
  async onApplicantChanged() {
    this.provider = null;
    if (!this.selectedApplicant) {
      return;
    }

    this.createMemoryProvider();
  }

  createMemoryProvider() {
    const crows: any[] = [];
    let row: any = {};
    for (const crit of this.criteria) {
      row = {
        criterion: crit,
        applicant: this.selectedApplicant,
        scores: []
      };
      const scores = this.selectedApplicant.scores.filter(
        (s: any) => s.criterionId === crit.id
      );
      for (let score = 0; score < this.maxScore; score++) {
        const found = scores.filter((s: any) => s.scorerNumber === score);
        if (found.length > 0) {
          row.scores.push(found[0]);
        } else {
          const sc = {
            id: Guid.create().toString(),
            scorerNumber: score,
            applicantId: this.selectedApplicant.id,

            criterionId: crit.id,
            value: 0
          };
          row.scores.push(sc);
          this.selectedApplicant.scores.push(sc);
        }
      }
      crows.push(row);
    }
    this.provider = new InMemoryDataProvider(crows);
  }

  isParentCriterionValid(parent: any, appl: any, num: number) {
    let sum = 0;
    const parentScore = appl.scores.filter(
      (s: any) => s.criterionId === parent.id && s.scorerNumber === num
    )[0];
    if (parentScore === undefined) {
      return true;
    }
    const found = appl.scores.filter((s: any) =>
      parent.childCriteria.some(
        (cc: any) => cc.id === s.criterionId && s.scorerNumber === num
      )
    );
    for (const f of found) {
      sum += parseFloat(f.value);
    }
    return sum === parseFloat(parentScore.value);
  }

  async submitScores() {
    if (!this.provider) {
      return;
    }
    const rows = (await this.provider?.fetchItemsAsync()).items;

    this.submitting = true;
    try {
      const allScores = rows.map((cc: any) => cc.scores.flat()).flat();
      await this.crud.addAsync(allScores);
    } finally {
      this.submitting = false;
    }
  }

  async download(id: string) {
    saveAs(
      await this.$service.downloadApplicantScores(id),
      `Scores_${this.openCallName}_${this.utc}.xlsx`
    );
  }

  async upload(file: File, id: string) {
    await this.$service.uploadApplicantScores(file, id);
    await this.initialize();
    this.createMemoryProvider();
  }

  async print(id: string, name: string) {
    //openPdf(this.$service.printApplicantScores(id));

    saveAs(
      await this.$service.printApplicantScores(id),
      `${this.openCallName}_${name}_Scores_${this.utc}.pdf`
    );
  }
}
