var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-card',[_c('loader',{attrs:{"retry":_vm.initialize}},[_c('div',{staticClass:"q-ma-md"},[_c('q-select',{attrs:{"label":"Applicant","options":_vm.applicants,"filled":"","option-label":function (a) { return (a ? a.name : ''); },"map-options":""},model:{value:(_vm.selectedApplicant),callback:function ($$v) {_vm.selectedApplicant=$$v},expression:"selectedApplicant"}})],1),(_vm.selectedApplicant && _vm.provider)?_c('model-table',{ref:"table",attrs:{"viewPermission":function (s) { return true; },"hideTop":true,"selection":'none',"title":"Scores","provider":_vm.provider,"columns":_vm.columns,"rows-per-page-options":[0],"row-key":"name","separator":"cell","disabled":_vm.submitting,"disablePagination":true,"hide-pagination":"","binary-state-sort":"","showActions":false},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"text-h6 q-pr-md"},[_vm._v("Scores")]),_c('q-btn',{attrs:{"icon":"download","label":"Download","flat":"","no-caps":"","loading":_vm.$proxy['downloading' + _vm.id]},on:{"click":function($event){_vm.$runAsync('downloading' + _vm.id, function () { return _vm.download(_vm.selectedApplicant.id); }
            )}}}),_c('q-btn',{attrs:{"icon":"upload","label":"Upload","flat":"","no-caps":""},on:{"click":function($event){_vm.importDialog = true}}}),_c('import-dialog',{attrs:{"uploadFunction":function (file) { return _vm.upload(file, _vm.selectedApplicant.id); }},model:{value:(_vm.importDialog),callback:function ($$v) {_vm.importDialog=$$v},expression:"importDialog"}}),_c('q-btn',{attrs:{"label":"Print","loading":_vm.$proxy.printing,"flat":"","icon":"print"},on:{"click":function($event){_vm.$runAsync('printing', function () { return _vm.print(_vm.selectedApplicant.id, _vm.selectedApplicant.name); }
            )}}})]},proxy:true},{key:"body",fn:function(props){return [_c('q-tr',{attrs:{"props":props}},_vm._l((props.cols),function(col){return _c('q-td',{key:col.name,class:props.row.criterion.parentCriterionId == null ? 'bg-grey-3' : '',style:({
              'background-color':
                col.scorerNumber === undefined ||
                props.row.criterion.childCriteria.length === 0
                  ? ''
                  : _vm.isParentCriterionValid(
                      props.row.criterion,
                      props.row.applicant,
                      col.scorerNumber
                    )
                  ? ''
                  : 'red !important',
              color:
                col.scorerNumber === undefined ||
                props.row.criterion.childCriteria.length === 0
                  ? ''
                  : _vm.isParentCriterionValid(
                      props.row.criterion,
                      props.row.applicant,
                      col.scorerNumber
                    )
                  ? ''
                  : 'white !important'
            }),attrs:{"props":props}},[_vm._v(" "+_vm._s(col.field(props.row))+" "),(col.update)?_c('q-popup-edit',{attrs:{"disable":_vm.submitting,"value":col.field(props.row),"title":"Update score","buttons":col.field(props.row) != '' &&
                  col.field(props.row) <= props.row.criterion.maxScore},on:{"input":function($event){return col.update(props.row, $event)}}},[_c('q-input',{attrs:{"outlined":"","type":"number","min":"0","max":props.row.criterion.maxScore,"value":col.field(props.row),"dense":"","autofocus":"","hint":("max: " + (props.row.criterion.maxScore) + " weight: " + (props.row.criterion.weight)),"rules":[
                  function (str) { return parseFloat(str) <= props.row.criterion.maxScore ||
                    'Must be lower than max'; }
                ]},on:{"input":function($event){return col.update(props.row, $event)}}})],1):_vm._e()],1)}),1)]}}],null,false,583187732)}):_vm._e(),_c('div',{staticClass:"row justify-center"},[_c('div',{staticClass:"col-auto"},[_c('q-btn',{staticClass:"q-ma-md",attrs:{"unelevated":"","label":"Submit","color":"green-4","loading":_vm.submitting,"disable":!_vm.oneChange},on:{"click":_vm.submitScores}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }